<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>Default mails</h1>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="doSave">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-form-group
        v-for="item of mailTypes"
        :key="item"
        :label="$t(item)"
        label-cols-lg="2"
      >
        <model-list-select
          v-model="defaultMails[item]"
          :list="mails"
          option-text="name"
          option-value="id"
          placeholder="Choose template"
        ></model-list-select>
      </b-form-group>
    </b-card>
  </b-overlay>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
export default {
  components: { ModelListSelect },
  data() {
    return {
      isLoading: false,
      defaultMails: {},
    };
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("Mails/fetchAll");
    await this.$store.dispatch("DefaultMails/fetchAll");
    this.defaultMails = this.$store.getters["DefaultMails/all"];
    this.isLoading = false;
  },
  computed: {
    mailTypes() {
      return ["paymentConfirmation", "accessCodeRetrieval"];
    },
    mails() {
      return this.$store.state.Mails.mails.data || [];
    },
  },
  methods: {
    async doSave() {
      this.isLoading = true;
      await this.$store.dispatch("DefaultMails/update", this.defaultMails);
      this.$root.$bvToast.toast("Default mail settings saved!", {
        variant: "success",
      });
      this.isLoading = false;
    },
  },
};
</script>
